import { Controller } from "stimulus";
import { fabric } from "fabric";
import { SeatPositionCalculator } from "packs/table_management/seat_position_calculator";
import { TableStacking } from "packs/table_management/table_stacking";
import { TableTimers } from "packs/table_management/table_timers";

export default class extends Controller {
  static targets = ["reservations", "waitlist", "all"];

  connect() {
    const canvas = new fabric.Canvas("canvas");
    document.getElementById("canvas").fabric = canvas;

    this.#setupCanvas();
    this.#setupDrag();
    this.#tableModal();
  }

  #setupCanvas() {
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    const center = canvas.getCenter();
    const centerPoint = new fabric.Point(center.left, center.top);
    const floorID = document.querySelector("#canvas").dataset.id;
    canvas.selection = false;

    if (floorID) {
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        url: `/restaurant/floor_plans/${floorID}/tables`,
        success: function (data) {
          let vpt = canvas.viewportTransform;
          vpt[0] = parseFloat(htmlCanvas.dataset.zoom);
          vpt[3] = parseFloat(htmlCanvas.dataset.zoom);
          vpt[4] = parseFloat(htmlCanvas.dataset.translatex);
          vpt[5] = parseFloat(htmlCanvas.dataset.translatey);
          canvas.setViewportTransform(vpt);
          canvas.requestRenderAll();
          const tables = data.floor_plan.table_configurations.map((tc) => ({
            ...tc,
            ...tc.table_attributes,
          }));

          const dividers = data.floor_plan.dividers.map((divider) => ({
            ...divider,
          }));

          // Combine tables and dividers into a single array
          const canvasObjects = tables.concat(dividers);

          const canvasData = { objects: canvasObjects };

          canvas.loadFromJSON(
            JSON.stringify(canvasData),
            canvas.renderAll.bind(canvas)
          );
          setupTables();
          tableStacking(document.querySelector("#canvas").dataset.id);
          tableTimers(document.querySelector("#canvas").dataset.id);
          const pollingInterval = setInterval(
            () => tableTimers(document.querySelector("#canvas").dataset.id),
            60000
          );
        },
        error: function (data) {
          console.log(data);
        },
      });
    }

    function tableStacking(floorId) {
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        type: "POST",
        url: `/restaurant/floor_plans/${floorId}/table_stacking`,
        success: function (data) {
          let tableStacker = new TableStacking(JSON.parse(data.table_stacks));
          tableStacker.stack();
        },
        error: function (data) {
          console.log(data);
        },
      });
    }

    function tableTimers(floorId) {
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        type: "POST",
        url: `/restaurant/floor_plans/${floorId}/table_timers`,
        success: function (data) {
          let tableTimer = new TableTimers(JSON.parse(data.table_timers));
          tableTimer.addTimers();
        },
        error: function (data) {
          console.log(data);
        },
      });
    }

    function setupTables() {
      var obj = canvas.getObjects();
      obj.forEach(function (item, i) {
        item.lockMovementX = true;
        item.lockMovementY = true;
        item.lockUniScaling = true;
        item.lockRotation = true;
        item.selectable = false;

        addTableNumber(item);
        addSeats(item);
        listenForTableDrop(item);
        hoverAnimation(item);
      });
    }

    function addTableNumber(item) {
      if (!item.number) return;
      var text = new fabric.Text(item.number.toString(), {
        fontSize: 16,
        originX: "center",
        originY: "center",
        fill: "#000",
        selectable: false,
      });

      var bg = new fabric.Rect({
        fill: "#d8d9da",
        scaleY: 0.5,
        originX: "center",
        originY: "center",
        rx: 5,
        ry: 5,
        width: text.width + 5,
        height: 40,
        selectable: false,
      });

      var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

      // Determine which algorithm to use based on the rotation angle
      if (angle === 90 || angle === 270) {
        // Use height-based positioning for 90 or 270 degrees
        var group = new fabric.Group([bg, text], {
          left: item.left - (item.height * item.scaleX) / 2 - 10,
          top: item.top - (item.width * item.scaleY) / 2 - 10,
          selectable: false,
        });
      } else {
        // Use width-based positioning for 0, 180, or other angles
        var group = new fabric.Group([bg, text], {
          left: item.left - (item.width * item.scaleX) / 2 - 10,
          top: item.top - (item.height * item.scaleY) / 2 - 10,
          selectable: false,
        });
      }

      canvas.add(group);
    }

    function addSeats(item) {
      let seatPositionCalculator = new SeatPositionCalculator(
        item.id,
        item.seats,
        item.shape,
        item.left,
        item.top,
        item.height,
        item.width,
        item.radius,
        item.scaleX,
        item.scaleY,
        item.angle,
        item
      );

      seatPositionCalculator.addSeatsToCanvas();
    }

    function listenForTableDrop(item) {
      item.on("drop", function (options) {
        if (
          options.target &&
          options.target.id &&
          (options.target.kind == "table" || options.target.kind == "bar")
        ) {
          $.ajax({
            dataType: "json",
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                $('meta[name="csrf-token"]').attr("content")
              );
            },
            type: "PUT",
            url: `/restaurant/floor_plans/${htmlCanvas.dataset.id}/tables/${item.table_id}/seat`,
            data: {
              obj_id: htmlCanvas.dataset.currentObj,
              type: htmlCanvas.dataset.objType,
            },
            success: function (data) {
              // Success : Update Object Row With Table Number, Show flash, add reservation name, timer, update color
              bootstrap.Toast.getOrCreateInstance(
                document.querySelector(".success")
              ).show();

              const objectRow = document.querySelector(`#obj${data.object_id}`);
              const accordion = document.querySelector(
                `#accordion-${data.object_id}`
              );
              objectRow.remove();
              accordion.remove();

              item.set("fill", data.table_color);

              let tableTimer = new TableTimers(data.table_timers);
              tableTimer.removeTimers();
              tableTimer.addTimers();

              let tableStacker = new TableStacking(data.table_stacks);
              tableStacker.remove();
              tableStacker.stack();

              canvas.renderAll();
            },
            error: function (data) {
              var msg = "An unexpected error has occurred.";
              if (data.responseJSON.message) msg = data.responseJSON.message;
              const toast = bootstrap.Toast.getOrCreateInstance(
                document.querySelector(".error")
              );
              document
                .querySelector(".error")
                .querySelector(".toast-body").innerHTML = msg;
              toast.show();

              if (data.responseJSON.table_color) {
                item.set("fill", data.responseJSON.table_color);
                canvas.renderAll();
              }
            },
          });
        }
      });
    }

    function hoverAnimation(item) {
      if (item.kind == "divider") return;
      let originalFill = item.fill;

      // On drop, the drag even stays active which causes dragleave events to be triggered later on.
      // Thus the item.fill stuff

      item.on("dragover", function () {
        if (item.fill != "#AC1EE7") originalFill = item.fill;
        item.set("fill", "#AC1EE7");
        canvas.renderAll();
      });

      item.on("dragleave", function () {
        if (item.fill != "#AC1EE7") return;
        item.set("fill", originalFill);
        canvas.renderAll();
      });
    }
  }

  #setupDrag() {
    const htmlCanvas = document.querySelector("#canvas");

    var rows = document.querySelectorAll(".draggable-row");

    function handleDragStart(event) {
      htmlCanvas.dataset.currentObj = event.target.dataset.id;
      htmlCanvas.dataset.objType = event.target.dataset.type;
    }

    [].forEach.call(rows, function (row) {
      row.addEventListener("dragstart", handleDragStart, false);
    });
  }

  #tableModal() {
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    canvas.on("mouse:down", function (options) {
      if (!options.target) return;
      if (
        options.target.id &&
        (options.target.kind == "table" || options.target.kind == "bar")
      ) {
        $.ajax({
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          type: "GET",
          url: `/restaurant/floor_plans/${htmlCanvas.dataset.id}/tables/${options.target.table_id}/modal`,
          success: function (data) {
            bootstrap.Modal.getOrCreateInstance(
              document.querySelector("#tableModal")
            ).show();
          },
          error: function (data) {
            var msg = "An unexpected error has occurred.";
            const toast = bootstrap.Toast.getOrCreateInstance(
              document.querySelector(".error")
            );
            document
              .querySelector(".error")
              .querySelector(".toast-body").innerHTML = msg;
            toast.show();
          },
        });
      }
    });
  }

  loadReservations(event) {
    const [_data, _status, xhr] = event.detail;
    this.reservationsTarget.innerHTML = xhr.response;

    document.querySelector("#waitlist").classList.add("d-none");
    document.querySelector("#all").classList.add("d-none");
    document.querySelector("#reservations").classList.remove("d-none");

    document.querySelector("#waitlist").dataset.open = false;
    document.querySelector("#all").dataset.open = false;
    document.querySelector("#reservations").dataset.open = true;

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl);
    });

    this.#setupDrag();
  }

  loadWaitlist(event) {
    const [_data, _status, xhr] = event.detail;
    this.waitlistTarget.innerHTML = xhr.response;

    document.querySelector("#reservations").classList.add("d-none");
    document.querySelector("#all").classList.add("d-none");
    document.querySelector("#waitlist").classList.remove("d-none");

    document.querySelector("#waitlist").dataset.open = true;
    document.querySelector("#all").dataset.open = false;
    document.querySelector("#reservations").dataset.open = false;

    this.#setupDrag();
  }

  reservationError(event) {
    const [_data, _status, xhr] = event.detail;
    document.querySelector(".reservationError").innerHTML = xhr.response;

    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".error")
    );
    document.querySelector(".error").querySelector(".toast-body").innerHTML =
      "An error has occurred.";
    toast.show();
  }

  waitingListEntryError(event) {
    const [_data, _status, xhr] = event.detail;
    document.querySelector("#waitingListEntryError").innerHTML = xhr.response;

    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".error")
    );
    document.querySelector(".error").querySelector(".toast-body").innerHTML =
      "An error has occurred.";
    toast.show();
  }

  detailsModal(event) {
    const [_data, _status, xhr] = event.detail;
    document.querySelector(".details").innerHTML = xhr.response;

    var mealModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("detailsModal")
    );
    mealModal.show();
  }

  stackTable(event) {
    const [data, _status, _xhr] = event.detail;
    let tableStacker = new TableStacking(JSON.parse(data.payload));

    tableStacker.remove();
    tableStacker.stack();

    let collapsedElement = document.querySelector(".accordion-collapse.show");
    if (!collapsedElement) return;

    let collapseInstance = new bootstrap.Collapse(collapsedElement, {
      toggle: false,
    });
    collapseInstance.hide();

    let collapsedParent = collapsedElement.parentNode;
    let tableNumber = collapsedParent.querySelector(".table-number");
    if (tableNumber) tableNumber.remove();

    if (!data.table_number) return;

    var firstDiv = collapsedParent.querySelector(".info-party-size");

    // Insert a new div right after the first div using insertAdjacentHTML
    firstDiv.insertAdjacentHTML(
      "afterend",
      `<div class='table-number bg-gray-200 me-1 fw-semibold d-flex justify-content-center align-items-center rounded text-black' style='width: 24px;height:24px;'>
      ${data.table_number}
    </div>`
    );
  }

  restackTables(event) {
    const [data, _status, _xhr] = event.detail;

    let tableStacker = new TableStacking(JSON.parse(data.payload));
    tableStacker.remove();
    tableStacker.stack();

    if (data.confirmed) {
      const element = document.querySelector("#confirmed_" + data.id);
      element.remove();
    } else if (data.remove_table_number) {
      let tableNumber = document
        .querySelector(`[data-id='${data.id}']`)
        .querySelector(`.table-number`);
      tableNumber.remove();
      const element = document.querySelector("#remove_" + data.id);
      element.remove();
    } else if (data.remove_accordion) {
      let accordionParent = document.querySelector(`[data-id='${data.id}']`);
      let accordion = document.querySelector(`#accordion-${data.id}`);
      accordionParent.remove();
      accordion.remove();
    } else if (data.id) {
      const element = document.querySelector("#remove_" + data.id);
      element.remove();
    }
  }

  toggleTableStacking(event) {
    const [data, _status, _xhr] = event.detail;

    let tableStacker = new TableStacking(JSON.parse(data.payload));
    tableStacker.remove();

    if (data.table_stacking_enabled) tableStacker.stack();
  }

  seated(event) {
    const [data, _status, _xhr] = event.detail;
    // Success : Update Object Row With Table Number, Show flash, add reservation name, timer, update color

    const canvas = document.querySelector("#canvas").fabric;
    const fabricObjects = canvas.getObjects();
    let item;
    fabricObjects.forEach(function (object) {
      if (object.kind == "table" && object.table_id == data.table_id) {
        item = object;
        return;
      }
    });

    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector("#tableModal")
    ).hide();

    const objectRow = document.querySelector(`#obj${data.object_id}`);
    const accordion = document.querySelector(`#accordion-${data.object_id}`);
    if (objectRow) {
      objectRow.remove();
      accordion.remove();
    }

    item.set("fill", data.table_color);

    let tableTimer = new TableTimers(data.table_timers);
    tableTimer.removeTimers();
    tableTimer.addTimers();

    let tableStacker = new TableStacking(data.table_stacks);
    tableStacker.remove();
    tableStacker.stack();

    canvas.renderAll();
  }
}
