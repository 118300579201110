import consumer from "./consumer";
import { TableStacking } from "packs/table_management/table_stacking";

consumer.subscriptions.create("TableManagementChannel", {
  connected() {},

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (
      !document.querySelector('[data-controller="table-management--index"]')
    ) {
      return;
    }

    const reservationTab = document.getElementById("reservations");
    if (reservationTab.getAttribute("data-open") === "true") {
      this.updateReservations(data.floor_plan_id);
    }

    const allTab = document.getElementById("all");
    if (allTab.getAttribute("data-open") === "true") {
      this.updateAll(data.floor_plan_id);
    }

    this.tableStacking(data.floor_plan_id);
  },

  tableStacking(floorId) {
    $.ajax({
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      type: "POST",
      url: `/restaurant/floor_plans/${floorId}/table_stacking`,
      success: function (data) {
        let tableStacker = new TableStacking(JSON.parse(data.table_stacks));
        tableStacker.stack();
      },
      error: function (data) {
        console.log(data);
      },
    });
  },

  updateReservations(floorPlanId) {
    fetch(
      "/restaurant/floor_plans/reservations?" +
        new URLSearchParams({ floor_plan_id: floorPlanId }),
      {
        method: "GET",
        headers: {
          "X-CSRF-Token": this.getCSRFToken(),
          "Content-Type": "application/json",
          Accept: "text/html",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((html) => {
        document.getElementById("reservations").innerHTML = html;
        setTimeout(() => {
          this.setupToolTips();
          this.setupDrag();
        }, 100);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  },

  updateAll(floorPlanId) {
    fetch(
      "/restaurant/floor_plans/load_all?" +
        new URLSearchParams({ floor_plan_id: floorPlanId }),
      {
        method: "GET",
        headers: {
          "X-CSRF-Token": this.getCSRFToken(),
          "Content-Type": "application/json",
          Accept: "text/html",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((html) => {
        document.getElementById("all").innerHTML = html;
        setTimeout(() => {
          this.setupToolTips();
          this.setupDrag();
        }, 100);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  },

  setupDrag() {
    const htmlCanvas = document.querySelector("#canvas");
    const rows = document.querySelectorAll(".draggable-row");

    const handleDragStart = (event) => {
      htmlCanvas.dataset.currentObj = event.target.dataset.id;
      htmlCanvas.dataset.objType = event.target.dataset.type;
    };

    rows.forEach((row) => {
      row.addEventListener("dragstart", handleDragStart, false);
    });
  },

  getCSRFToken() {
    return document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },

  setupToolTips() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl);
    });
  },
});
