import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["partialToUpdate", "modalForm"];

  connect() {}

  updateHtml(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;
  }

  updateDataTable(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    $("#dataTable").DataTable({
      paging: this.partialToUpdateTarget.dataset.paging === "true",
      searching: this.partialToUpdateTarget.dataset.scrolling === "true",
      columnDefs: [{ type: "date-range", targets: 0 }],
    });
  }

  ajaxForm(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    var ajaxModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("ajaxModal")
    );

    this.#instantiateFlatpickr();
    ajaxModal.show();
  }

  clearHtml() {
    this.partialToUpdateTarget.innerHTML = "";
  }

  closeModal(event) {
    const [data, _status, _xhr] = event.detail;

    var modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById(data.modalId)
    );

    modal.hide();
  }

  successToast() {
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();
  }

  closeModalSuccess(event) {
    this.clearHtml();
    this.closeModal(event);
    this.successToast();
  }

  ajaxFlatpickrMultiForm(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    flatpickr(".flatpickr-multi", {
      altInput: true,
      mode: "multiple",
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      minDate: "today",
      disableMobile: true,
    });

    var ajaxModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("ajaxModal")
    );
    ajaxModal.show();
  }

  ajaxFlatpickrTimes(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    flatpickr(".flatpickr-times", {
      altInput: true,
      altFormat: "F j, Y h:i K", // Display format with time
      dateFormat: "Y-m-d H:i", // Submission format with time
      enableTime: true, // Enable time selection
      time_24hr: false, // Use 12-hour format
      disableMobile: true,
      minuteIncrement: 15,
    });

    var ajaxModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("ajaxModal")
    );
    ajaxModal.show();
  }

  #instantiateFlatpickr() {
    flatpickr(".flatpickr", {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      disableMobile: true,
      minDate: "today",
    });
  }

  fetchForm(event) {
    const [_data, _status, xhr] = event.detail;
    this.modalFormTarget.innerHTML = xhr.response;

    // Show the modal after the form is populated
    const modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("editModal")
    );
    modal.show();
  }

  handleError(event) {
    const [_data, _status, xhr] = event.detail;
    this.modalFormTarget.innerHTML = xhr.response;
  }

  handleSuccess(event) {
    const modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("editModal")
    );
    modal.hide();
    // this.successToast();
  }
}
