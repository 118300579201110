import { Controller } from "stimulus";
import { fabric } from "fabric";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { SeatPositionCalculator } from "packs/table_management/seat_position_calculator";

export default class extends Controller {
  static targets = ["sidebar"];

  connect() {
    const canvas = new fabric.Canvas("canvas");
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(
      document.getElementById("properties-collapse"),
      {
        toggle: false,
      }
    );
    document.getElementById("canvas").fabric = canvas;

    this.#setupCanvas();
    this.#canvasListeners();
    this.#multiSelection();
  }

  #setupCanvas() {
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    const center = canvas.getCenter();
    const centerPoint = new fabric.Point(center.left, center.top);
    const floorID = document.querySelector("#canvas").dataset.id;

    // Get Tables
    $.ajax({
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      url: `/restaurant/floor_plans/${floorID}/tables`,
      success: function (data) {
        let vpt = canvas.viewportTransform;
        vpt[0] = parseFloat(htmlCanvas.dataset.zoom);
        vpt[3] = parseFloat(htmlCanvas.dataset.zoom);
        vpt[4] = parseFloat(htmlCanvas.dataset.translatex);
        vpt[5] = parseFloat(htmlCanvas.dataset.translatey);
        canvas.setViewportTransform(vpt);
        canvas.requestRenderAll();

        const tables = data.floor_plan.table_configurations.map((tc) => ({
          ...tc,
          ...tc.table_attributes,
        }));

        const dividers = data.floor_plan.dividers.map((divider) => ({
          ...divider,
        }));

        // Combine tables and dividers into a single array
        const canvasObjects = tables.concat(dividers);

        const canvasData = { objects: canvasObjects };

        canvas.loadFromJSON(
          JSON.stringify(canvasData),
          canvas.renderAll.bind(canvas)
        );
        setupNumbers();
      },
      error: function (data) {
        const toast = bootstrap.Toast.getOrCreateInstance(
          document.querySelector(".error")
        );
        document
          .querySelector(".error")
          .querySelector(".toast-body").innerHTML = "An error has occurred.";
        toast.show();
      },
    });

    function setupNumbers() {
      var obj = canvas.getObjects();
      obj.forEach(function (item, i) {
        let seatPositionCalculator = new SeatPositionCalculator(
          item.id,
          item.seats,
          item.shape,
          item.left,
          item.top,
          item.height,
          item.width,
          item.radius,
          item.scaleX,
          item.scaleY,
          item.angle,
          item
        );

        seatPositionCalculator.addSeatsToCanvas();
        if (!item.number) return;
        var text = new fabric.Text(item.number.toString(), {
          fontSize: 16,
          originX: "center",
          originY: "center",
          fill: "#000",
          selectable: false,
        });

        var bg = new fabric.Rect({
          fill: "#d8d9da",
          scaleY: 0.5,
          originX: "center",
          originY: "center",
          rx: 5,
          ry: 5,
          width: text.width + 5,
          height: 40,
          selectable: false,
        });

        var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

        // Determine which algorithm to use based on the rotation angle
        if (angle === 90 || angle === 270) {
          // Use height-based positioning for 90 or 270 degrees
          var group = new fabric.Group([bg, text], {
            left: item.left - (item.height * item.scaleX) / 2 - 10,
            top: item.top - (item.width * item.scaleY) / 2 - 10,
            tableId: item.id,
            selectable: true,
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
          });
        } else {
          // Use width-based positioning for 0, 180, or other angles
          var group = new fabric.Group([bg, text], {
            left: item.left - (item.width * item.scaleX) / 2 - 10,
            top: item.top - (item.height * item.scaleY) / 2 - 10,
            tableId: item.id,
            selectable: true,
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
          });
        }

        canvas.add(group);

        item.on("moving", function () {
          // Make the table number follow table when moving
          var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

          // Determine which algorithm to use based on the rotation angle
          if (angle === 90 || angle === 270) {
            // Use height-based positioning for 90 or 270 degrees
            group.set({
              left: item.left - (item.height * item.scaleX) / 2 - 10,
              top: item.top - (item.width * item.scaleY) / 2 - 10,
            });
          } else {
            // Use width-based positioning for 0, 180, or other angles
            group.set({
              left: item.left - (item.width * item.scaleX) / 2 - 10,
              top: item.top - (item.height * item.scaleY) / 2 - 10,
            });
          }
          canvas.renderAll();
        });
      });
    }
  }

  #canvasListeners() {
    const canvas = document.querySelector("#canvas").fabric;
    const tableForm = document.querySelector(".tableForm");
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(
      document.getElementById("properties-collapse"),
      {
        toggle: false,
      }
    );

    canvas.on("mouse:down", function (options) {
      if (!options.target) return;
      var activeObject = canvas.getActiveObject();
      // Handle Table Click
      if (activeObject && activeObject.type === "activeSelection") {
        bsCollapse.hide();
      } else if (
        options.target.kind == "table" ||
        options.target.kind == "bar"
      ) {
        var selected = options.target;

        document.querySelector("#tableNumber").value = selected.number;
        document.querySelector("#seats").value = selected.seats;
        document.querySelector("#sections").value = selected.section_id;

        tableForm.classList.remove("d-none");
        bsCollapse.show();
      }
      // Handle Non Table Click (Divider)
      if (options.target.kind == "divider") {
        tableForm.classList.add("d-none");
        bsCollapse.hide();
      }
    });

    canvas.on("selection:cleared", function (options) {
      bsCollapse.hide();
      tableForm.reset();
      tableForm.classList.add("d-none");
    });

    //Snap to Grid
    canvas.on("object:moving", function (options) {
      const grid = 10;
      options.target.set({
        left: Math.round(options.target.left / grid) * grid,
        top: Math.round(options.target.top / grid) * grid,
      });
    });

    canvas.on("object:rotating", function (event) {
      var obj = event.target;
      // Calculate the nearest 15 degree angle
      var angle = Math.round(obj.angle / 15) * 15;
      // Set the object's angle to the nearest 90 degrees
      obj.set("angle", angle);
    });
  }

  #multiSelection() {
    const canvas = document.querySelector("#canvas").fabric;

    canvas.on("mouse:down", function (options) {
      if (options.e.shiftKey && options.target) {
        var clickedObject = options.target;
        var allObjects = canvas.getObjects();
        var toBeSelected = [clickedObject];

        // Check if the clicked object has an id and find all objects with matching table_id
        if (clickedObject.id) {
          allObjects.forEach((obj) => {
            if (obj.tableId === clickedObject.id && obj !== clickedObject) {
              toBeSelected.push(obj);
            }
          });
        }

        var activeObject = canvas.getActiveObject();

        if (activeObject && activeObject.type === "activeSelection") {
          // Add each matched object to the current group selection
          toBeSelected.forEach((obj) => {
            if (!activeObject.contains(obj)) {
              activeObject.addWithUpdate(obj);
            }
          });
        } else {
          // Create a new active selection with the matched objects
          var group = new fabric.ActiveSelection(toBeSelected, {
            canvas: canvas,
          });
          canvas.setActiveObject(group);
        }
        canvas.requestRenderAll();
      }
    });

    // Prevent default grouping behavior to allow custom handling
    canvas.on("selection:created", function (e) {
      if (
        canvas.getActiveObject().type === "activeSelection" &&
        !e.e.shiftKey
      ) {
        canvas.discardActiveObject();
        canvas.setActiveObject(e.target);
      }
    });
  }

  saveProperties(event) {
    event.preventDefault();
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    const activeObject = canvas.getActiveObject();
    const tableForm = document.querySelector(".tableForm");
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(
      document.getElementById("properties-collapse")
    );

    activeObject.number = document.querySelector("#tableNumber").value;
    activeObject.seats = document.querySelector("#seats").value;
    activeObject.section_id = document.querySelector("#sections").value;

    if (activeObject.table_id) {
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        type: "PUT",
        url: `/restaurant/floor_plans/${htmlCanvas.dataset.id}/tables/${activeObject.table_id}`,
        data: {
          table: {
            number: activeObject.number,
            seats: activeObject.seats,
            section_id: activeObject.section_id,
            width: activeObject.width,
            height: activeObject.height,
            shape: activeObject.shape,
            table_configurations_attributes: [
              {
                id: activeObject.id,
                table_id: activeObject.table_id,
                floor_plan_id: htmlCanvas.dataset.id,
                angle: activeObject.angle,
                backgroundColor: activeObject.backgroundColor,
                //fill: activeObject.fill,
                fillRule: activeObject.fillRule,
                flipX: activeObject.flipX,
                flipY: activeObject.flipY,
                globalCompositeOperation: activeObject.globalCompositeOperation,
                height: activeObject.height,
                left: activeObject.left,
                opacity: activeObject.opacity,
                originX: activeObject.originX,
                originY: activeObject.originY,
                paintFirst: activeObject.paintFirst,
                rx: activeObject.rx,
                ry: activeObject.ry,
                scaleX: activeObject.scaleX,
                scaleY: activeObject.scaleY,
                shadow: activeObject.shadow,
                skewX: activeObject.skewX,
                skewY: activeObject.skewY,
                stroke: activeObject.stroke,
                strokeDashArray: activeObject.strokeDashArray,
                strokeDashOffset: activeObject.strokeDashOffset,
                strokeLineCap: activeObject.strokeLineCap,
                strokeLineJoin: activeObject.strokeLineJoin,
                strokeMiterLimit: activeObject.strokeMiterLimit,
                strokeUniform: activeObject.strokeUniform,
                strokeWidth: activeObject.strokeWidth,
                top: activeObject.top,
                type: activeObject.type,
                version: activeObject.version,
                visible: activeObject.visible,
                width: activeObject.width,
                radius: activeObject.radius,
                startAngle: activeObject.startAngle,
                endAngle: activeObject.endAngle,
                lockScalingX: activeObject.lockScalingX,
                lockScalingY: activeObject.lockScalingY,
              },
            ],
          },
        },
        success: function (data) {
          console.log(data);
          bsCollapse.hide();
          tableForm.reset();
          tableForm.classList.add("d-none");
          activeObject.set({
            stroke: data.color,
            strokeWidth: 4,
          });
          let numberGroup = findGroupByTableId(activeObject.id);
          canvas.remove(numberGroup);
          addTableNumber(activeObject);
          canvas.renderAll();
          bootstrap.Toast.getOrCreateInstance(
            document.querySelector(".success")
          ).show();
          let seatPositionCalculator = new SeatPositionCalculator(
            activeObject.id,
            activeObject.seats,
            activeObject.shape,
            activeObject.left,
            activeObject.top,
            activeObject.height,
            activeObject.width,
            activeObject.radius,
            activeObject.scaleX,
            activeObject.scaleY,
            activeObject.angle,
            activeObject
          );

          seatPositionCalculator.addSeatsToCanvas();
        },
        error: function (data) {
          const toast = bootstrap.Toast.getOrCreateInstance(
            document.querySelector(".error")
          );
          document
            .querySelector(".error")
            .querySelector(".toast-body").innerHTML = data.responseJSON.message;
          toast.show();
        },
      });
    } else {
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        type: "POST",
        url: `/restaurant/floor_plans/${htmlCanvas.dataset.id}/tables`,
        data: {
          table: {
            number: activeObject.number,
            seats: activeObject.seats,
            section_id: activeObject.section_id,
            width: activeObject.width,
            height: activeObject.height,
            shape: activeObject.shape,
            table_configurations_attributes: {
              angle: activeObject.angle,
              backgroundColor: activeObject.backgroundColor,
              fill: activeObject.fill,
              fillRule: activeObject.fillRule,
              flipX: activeObject.flipX,
              flipY: activeObject.flipY,
              globalCompositeOperation: activeObject.globalCompositeOperation,
              height: activeObject.height,
              left: activeObject.left,
              opacity: activeObject.opacity,
              originX: activeObject.originX,
              originY: activeObject.originY,
              paintFirst: activeObject.paintFirst,
              rx: activeObject.rx,
              ry: activeObject.ry,
              scaleX: activeObject.scaleX,
              scaleY: activeObject.scaleY,
              shadow: activeObject.shadow,
              skewX: activeObject.skewX,
              skewY: activeObject.skewY,
              stroke: activeObject.stroke,
              strokeDashArray: activeObject.strokeDashArray,
              strokeDashOffset: activeObject.strokeDashOffset,
              strokeLineCap: activeObject.strokeLineCap,
              strokeLineJoin: activeObject.strokeLineJoin,
              strokeMiterLimit: activeObject.strokeMiterLimit,
              strokeUniform: activeObject.strokeUniform,
              strokeWidth: activeObject.strokeWidth,
              top: activeObject.top,
              type: activeObject.type,
              version: activeObject.version,
              visible: activeObject.visible,
              width: activeObject.width,
              radius: activeObject.radius,
              startAngle: activeObject.startAngle,
              endAngle: activeObject.endAngle,
              lockScalingX: activeObject.lockScalingX,
              lockScalingY: activeObject.lockScalingY,
            },
          },
        },
        success: function (data) {
          bsCollapse.hide();
          tableForm.reset();
          tableForm.classList.add("d-none");
          activeObject.set({
            id: data.id,
            table_id: data.table_id,
            stroke: data.color,
            strokeWidth: 4,
          });
          addTableNumber(activeObject);
          canvas.renderAll();
          bootstrap.Toast.getOrCreateInstance(
            document.querySelector(".success")
          ).show();
          canvas.forEachObject(function (object) {
            if (
              object.type == "group" &&
              object.kind == 0 &&
              object.tableId == activeObject.seatRef
            ) {
              canvas.remove(object);
            }
          });
          let seatPositionCalculator = new SeatPositionCalculator(
            activeObject.id,
            activeObject.seats,
            activeObject.shape,
            activeObject.left,
            activeObject.top,
            activeObject.height,
            activeObject.width,
            activeObject.radius,
            activeObject.scaleX,
            activeObject.scaleY,
            activeObject.angle,
            activeObject
          );

          seatPositionCalculator.addSeatsToCanvas();
        },
        error: function (data) {
          const toast = bootstrap.Toast.getOrCreateInstance(
            document.querySelector(".error")
          );
          document
            .querySelector(".error")
            .querySelector(".toast-body").innerHTML = data.responseJSON.message;
          toast.show();
        },
      });
    }

    function addTableNumber(item) {
      if (!item.number) return;
      var text = new fabric.Text(item.number.toString(), {
        fontSize: 16,
        originX: "center",
        originY: "center",
        fill: "#000",
        selectable: false,
      });

      var bg = new fabric.Rect({
        fill: "#d8d9da",
        scaleY: 0.5,
        originX: "center",
        originY: "center",
        rx: 5,
        ry: 5,
        width: text.width + 5,
        height: 40,
        selectable: false,
      });

      var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

      // Determine which algorithm to use based on the rotation angle
      if (angle === 90 || angle === 270) {
        // Use height-based positioning for 90 or 270 degrees
        var group = new fabric.Group([bg, text], {
          left: item.left - (item.height * item.scaleX) / 2 - 10,
          top: item.top - (item.width * item.scaleY) / 2 - 10,
          tableId: item.id,
          selectable: true,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
        });
      } else {
        // Use width-based positioning for 0, 180, or other angles
        var group = new fabric.Group([bg, text], {
          left: item.left - (item.width * item.scaleX) / 2 - 10,
          top: item.top - (item.height * item.scaleY) / 2 - 10,
          tableId: item.id,
          selectable: true,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
        });
      }

      canvas.add(group);

      item.on("moving", function () {
        // Make the square (following object) mimic the circle's movement
        var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

        // Determine which algorithm to use based on the rotation angle
        if (angle === 90 || angle === 270) {
          // Use height-based positioning for 90 or 270 degrees
          group.set({
            left: item.left - (item.height * item.scaleX) / 2 - 10,
            top: item.top - (item.width * item.scaleY) / 2 - 10,
          });
        } else {
          // Use width-based positioning for 0, 180, or other angles
          group.set({
            left: item.left - (item.width * item.scaleX) / 2 - 10,
            top: item.top - (item.height * item.scaleY) / 2 - 10,
          });
        }
      });
    }

    function findGroupByTableId(tableId) {
      let foundGroup = null;

      canvas.forEachObject(function (object) {
        if (object.type === "group" && object.tableId === tableId) {
          foundGroup = object;
          return false; // Break the loop
        }
      });

      return foundGroup;
    }
  }

  saveFloorPlan() {
    const floorID = document.querySelector("#canvas").dataset.id;
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;

    canvas.discardActiveObject().renderAll();
    var json = canvas.toJSON([
      "id",
      "table_id",
      "floor_plan_id",
      "section_id",
      "number",
      "seats",
      "kind",
      "shape",
    ]);

    const table_configurations = json.objects
      .filter((object) => object.kind == "table" || object.kind == "bar")
      .map((object) => ({
        id: object.id,
        floor_plan_id: object.floor_plan_id,
        table_id: object.table_id,
        angle: object.angle,
        backgroundColor: object.backgroundColor,
        fill: object.fill,
        fillRule: object.fillRule,
        flipX: object.flipX,
        flipY: object.flipY,
        globalCompositeOperation: object.globalCompositeOperation,
        height: object.height,
        left: object.left,
        opacity: object.opacity,
        originX: object.originX,
        originY: object.originY,
        paintFirst: object.paintFirst,
        rx: object.rx,
        ry: object.ry,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
        shadow: object.shadow,
        skewX: object.skewX,
        skewY: object.skewY,
        stroke: object.stroke,
        strokeDashArray: object.strokeDashArray,
        strokeDashOffset: object.strokeDashOffset,
        strokeLineCap: object.strokeLineCap,
        strokeLineJoin: object.strokeLineJoin,
        strokeMiterLimit: object.strokeMiterLimit,
        strokeUniform: object.strokeUniform,
        strokeWidth: object.strokeWidth,
        top: object.top,
        type: object.type,
        version: object.version,
        visible: object.visible,
        width: object.width,
        radius: object.radius,
        startAngle: object.startAngle,
        endAngle: object.endAngle,
        lockScalingX: object.lockScalingX,
        lockScalingY: object.lockScalingY,
        table_attributes: {
          id: object.table_id,
          section_id: object.section_id,
          floor_plan_id: object.floor_plan_id,
          kind: object.kind,
          shape: object.shape,
          number: object.number,
          seats: object.seats,
          status: object.status,
        },
      }));

    $.ajax({
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      type: "PATCH",
      url: `/restaurant/floor_plans/${floorID}`,
      data: {
        floor_plan: {
          offset_x: htmlCanvas.dataset.offsetx,
          offset_y: htmlCanvas.dataset.offsety,
          zoom: htmlCanvas.dataset.zoom,
          translate_x: htmlCanvas.dataset.translatex,
          translate_y: htmlCanvas.dataset.translatey,
          table_configurations_attributes: table_configurations,
          dividers_attributes: json.objects.filter(
            (object) => object.kind == "divider"
          ),
        },
      },
      success: function (data) {
        bootstrap.Toast.getOrCreateInstance(
          document.querySelector(".success")
        ).show();
      },
      error: function (data) {
        const toast = bootstrap.Toast.getOrCreateInstance(
          document.querySelector(".error")
        );
        document
          .querySelector(".error")
          .querySelector(".toast-body").innerHTML = data.responseJSON.message;
        toast.show();
      },
    });
  }

  updateSidebar(event) {
    const [_data, _status, xhr] = event.detail;
    this.sidebarTarget.innerHTML = xhr.response;
    this.#canvasListeners();
  }

  successToast() {
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();
  }

  errorToast(event) {
    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".error")
    );
    document.querySelector(".error").querySelector(".toast-body").innerHTML =
      event.detail[0].message;
    toast.show();
  }
}
