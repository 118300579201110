import consumer from "./consumer"
import { Tooltip, Popover } from 'bootstrap/dist/js/bootstrap'

consumer.subscriptions.create("ReservationChannel", {
  connected() {

  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $('.dataTables_empty').remove()
    $('tbody.index tr:first').before(data.html);

    var row = document.getElementsByTagName("tbody")[0].getElementsByTagName("tr")[0]
    var tooltipTriggerList = [].slice.call(row.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(row.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl)
    })
  }
});
