import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import bootstrap from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ["menuForm", "mealForm"]

  connect() {
    this.initializeTooltips()
  }

  updateMenu(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = xhr.response
    this.mealFormTarget.innerHTML = ""

    this.removeClickedClass()
  }


  updateMeal(event) {
    const [_data, _status, xhr] = event.detail
    this.mealFormTarget.innerHTML = xhr.response

    var mealModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('mealModal'))
    mealModal.show()

    this.removeClickedClass()
    event.target.classList.add('clicked')
  }

  formSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = xhr.response

    if (_status != 'OK') { return false }

    this.updateMenuTabs()
    this.triggerToast()
    this.initializeTooltips()
  }

  newMenuSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = xhr.response

    if (_status != 'OK') { return false }

    var formModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('newMenuModal'))
    formModal.hide()

    this.updateMenuTabs()
    this.triggerToast()
    this.checkNewMenu()
  }

  mealFormSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.mealFormTarget.innerHTML = xhr.response

    if (_status != 'OK') { return false }

    var mealModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('mealModal'))
    mealModal.hide()

    this.triggerToast()
    if (event.target.dataset.menuid != null) { this.updateTabs(event.target.dataset.menuid) }
  }

  removeMenu(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = ""

    document.querySelector('#accordion_' + String(_data["menu"])).remove()
    this.triggerToast()
  }

  removeMeal(event) {
    const [_data, _status, xhr] = event.detail
    this.mealFormTarget.innerHTML = ""

    var mealModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('mealModal'))
    mealModal.hide()

    document.querySelector('#accordion_' + String(_data["menu_item"])).remove()
    this.triggerToast()
  }

  triggerToast(status) {
    var toast = bootstrap.Toast.getOrCreateInstance(document.querySelector('.success'))
    toast.show()
  }

  removeClickedClass() {
    const boxes = document.querySelectorAll('.nav-link-light.clicked');
    boxes.forEach(box => {
      box.classList.remove('clicked');
    });
  }

  updateTabs(menu_id) {
    $.ajax({
      url: "/restaurant/settings/online_orders/menus/update_meal_tabs",
      type: "get",
      data: {"menu_id": menu_id},
      success: function(data) {
        $('.accordion-collapse.show nav .menu_items').append(data)
      },
      error: function(data) {}
    })
  }

  updateMenuTabs() {
    const menuTabsElement = document.getElementById('menuTabs');
    if (menuTabsElement) {
      const updateUrl = menuTabsElement.getAttribute('data-update-url');
      if (updateUrl) {
        $.ajax({
          url: updateUrl,
          type: "get",
          success: function(data) {
            menuTabsElement.innerHTML = data;
          },
          error: function(data) {
            console.error("Error updating menu tabs:", data);
          }
        });
      } else {
        console.warn("Menu schedule does not exist; update URL not available.");
      }
    }
  }

  checkNewMenu() {
    var scheduleName = document.querySelector('.tab.btn-light-red').innerHTML

    $.ajax({
      url: "/restaurant/settings/online_orders/menus/check_new",
      type: "get",
      data: {"schedule_name": scheduleName},
      success: (data)=>{
        if (data["schedule_match"] == null) {
          document.querySelector('#menuForm').innerHTML = data
          this.updateMenuAccordion(scheduleName)
        }
      },
      error: function(data) {}
    })
  }

  updateMenuAccordion(scheduleName) {
    $.ajax({
      url: "/restaurant/settings/online_orders/menus/update_menu_accordion",
      type: "get",
      data: {"schedule_name": scheduleName},
      success: function(data) {
        document.querySelector('#menuAccordion').innerHTML = data
      },
      error: function(data) {}
    })
  }

  initializeTooltips() {
    const tooltipTriggerList = Array.from(
      this.element.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }
}
