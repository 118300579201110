import { Controller } from "stimulus"
import bootstrap from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ["modal"]

  connect() {}

  updateModal(event) {
    const [_data, _status, xhr] = event.detail
    this.modalTarget.innerHTML = xhr.response

    var modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('sortMeals'))
    modal.show()
  }
}
