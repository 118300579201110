import consumer from "./consumer";

consumer.subscriptions.create("TableSheetChannel", {
  connected() {},

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const tableRowElement = document.querySelector(
      `#table_row_${data.tableId}`
    );

    if (tableRowElement) {
      tableRowElement.innerHTML = data.html;
    }
  },
});
