import { Controller } from "stimulus";
import { fabric } from "fabric";
import { SeatPositionCalculator } from "packs/table_management/seat_position_calculator";
import { TableStacking } from "packs/table_management/table_stacking";

export default class extends Controller {
  static targets = ["reservations"];

  connect() {
    const canvas = new fabric.Canvas("canvas");
    document.getElementById("canvas").fabric = canvas;

    this.#setupCanvas();
    this.#tableModal();
  }

  #setupCanvas() {
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    const center = canvas.getCenter();
    const centerPoint = new fabric.Point(center.left, center.top);
    const floorID = document.querySelector("#canvas").dataset.id;

    if (floorID) {
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        url: `/restaurant/floor_plans/${floorID}/tables`,
        success: function (data) {
          let vpt = canvas.viewportTransform;
          vpt[0] = parseFloat(htmlCanvas.dataset.zoom);
          vpt[3] = parseFloat(htmlCanvas.dataset.zoom);
          vpt[4] = parseFloat(htmlCanvas.dataset.translatex);
          vpt[5] = parseFloat(htmlCanvas.dataset.translatey);
          canvas.setViewportTransform(vpt);
          canvas.requestRenderAll();
          const tables = data.floor_plan.table_configurations.map((tc) => ({
            ...tc,
            ...tc.table_attributes,
          }));

          const dividers = data.floor_plan.dividers.map((divider) => ({
            ...divider,
          }));

          // Combine tables and dividers into a single array
          const canvasObjects = tables.concat(dividers);

          const canvasData = { objects: canvasObjects };

          canvas.loadFromJSON(
            JSON.stringify(canvasData),
            canvas.renderAll.bind(canvas)
          );
          setupTables();
          tableStacking(document.querySelector("#canvas").dataset.id);
        },
        error: function (data) {
          console.log(data);
        },
      });
    }

    function tableStacking(floorId) {
      const url = new URL(window.location.href);
      const queryParams = new URLSearchParams(url.search);
      const date = queryParams.get("date");
      //test
      $.ajax({
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        type: "POST",
        data: { date: date },
        url: `/restaurant/floor_plans/${floorId}/table_stacking`,
        success: function (data) {
          let tableStacker = new TableStacking(JSON.parse(data.table_stacks));
          tableStacker.stack();
        },
        error: function (data) {
          console.log(data);
        },
      });
    }

    function setupTables() {
      var obj = canvas.getObjects();
      obj.forEach(function (item, i) {
        item.lockMovementX = true;
        item.lockMovementY = true;
        item.lockUniScaling = true;
        item.lockRotation = true;
        item.selectable = false;
        if (item.kind == "table") {
          if (item.fill != "#545454" && item.fill != "#d1d1d1") {
            item.set("fill", "#34AA27");
          }
          canvas.renderAll();
        }

        addTableNumber(item);
        addSeats(item);
      });
    }

    function addTableNumber(item) {
      if (!item.number) return;
      var text = new fabric.Text(item.number.toString(), {
        fontSize: 16,
        originX: "center",
        originY: "center",
        fill: "#000",
        selectable: false,
      });

      var bg = new fabric.Rect({
        fill: "#d8d9da",
        scaleY: 0.5,
        originX: "center",
        originY: "center",
        rx: 5,
        ry: 5,
        width: text.width + 5,
        height: 40,
        selectable: false,
      });

      var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

      // Determine which algorithm to use based on the rotation angle
      if (angle === 90 || angle === 270) {
        // Use height-based positioning for 90 or 270 degrees
        var group = new fabric.Group([bg, text], {
          left: item.left - (item.height * item.scaleX) / 2 - 10,
          top: item.top - (item.width * item.scaleY) / 2 - 10,
          selectable: false,
        });
      } else {
        // Use width-based positioning for 0, 180, or other angles
        var group = new fabric.Group([bg, text], {
          left: item.left - (item.width * item.scaleX) / 2 - 10,
          top: item.top - (item.height * item.scaleY) / 2 - 10,
          selectable: false,
        });
      }

      canvas.add(group);
    }

    function addSeats(item) {
      let seatPositionCalculator = new SeatPositionCalculator(
        item.id,
        item.seats,
        item.shape,
        item.left,
        item.top,
        item.height,
        item.width,
        item.radius,
        item.scaleX,
        item.scaleY,
        item.angle,
        item
      );

      seatPositionCalculator.addSeatsToCanvas();
    }
  }

  #tableModal() {
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    canvas.on("mouse:down", function (options) {
      if (!options.target) return;
      if (
        options.target.id &&
        (options.target.kind == "table" || options.target.kind == "bar")
      ) {
        const url = new URL(window.location.href);
        const queryParams = new URLSearchParams(url.search);
        const date = queryParams.get("date");

        $.ajax({
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          type: "GET",
          url: `/restaurant/floor_plans/${htmlCanvas.dataset.id}/tables/${options.target.table_id}/on_date_modal`,
          data: { date: date },
          success: function (data) {
            document.querySelector("#tableModal").innerHTML = data;
            bootstrap.Modal.getOrCreateInstance(
              document.querySelector("#tableModal")
            ).show();
            var popoverTriggerList = [].slice.call(
              document.querySelectorAll('[data-bs-toggle="popover"]')
            );
            var popoverList = popoverTriggerList.map(function (
              popoverTriggerEl
            ) {
              return new bootstrap.Popover(popoverTriggerEl);
            });

            var tooltipTriggerList = [].slice.call(
              document.querySelectorAll('[data-bs-toggle="tooltip"]')
            );
            var tooltipList = tooltipTriggerList.map(function (
              tooltipTriggerEl
            ) {
              var tooltip = new bootstrap.Tooltip(tooltipTriggerEl);
              tooltipTriggerEl.addEventListener("click", function () {
                tooltip.hide();
              });
            });
          },
          error: function (data) {
            var msg = "An unexpected error has occurred.";
            const toast = bootstrap.Toast.getOrCreateInstance(
              document.querySelector(".error")
            );
            document
              .querySelector(".error")
              .querySelector(".toast-body").innerHTML = msg;
            toast.show();
          },
        });
      }
    });
  }

  stackTable(event) {
    const [data, _status, _xhr] = event.detail;
    let tableStacker = new TableStacking(JSON.parse(data.payload));

    tableStacker.remove();
    tableStacker.stack();

    let collapsedElement = document.querySelector(".accordion-collapse.show");
    if (!collapsedElement) return;

    let collapseInstance = new bootstrap.Collapse(collapsedElement, {
      toggle: false,
    });
    collapseInstance.hide();

    let collapsedParent = collapsedElement.parentNode;
    let tableNumber = collapsedParent.querySelector(".table-number");
    if (tableNumber) tableNumber.remove();

    if (!data.table_number) return;

    var firstDiv = collapsedParent.querySelector(".info-party-size");

    // Insert a new div right after the first div using insertAdjacentHTML
    firstDiv.insertAdjacentHTML(
      "afterend",
      `<div class='table-number bg-gray-200 me-1 fw-semibold d-flex justify-content-center align-items-center rounded text-black' style='width: 24px;height:24px;'>
      ${data.table_number}
    </div>`
    );
  }

  loadReservations(event) {
    const [_data, _status, xhr] = event.detail;
    this.reservationsTarget.innerHTML = xhr.response;

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl);
    });
  }

  detailsModal(event) {
    const [_data, _status, xhr] = event.detail;
    document.querySelector(".details").innerHTML = xhr.response;

    var modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("detailsModal")
    );
    modal.show();
  }

  restackTables(event) {
    const [data, _status, _xhr] = event.detail;

    let tableStacker = new TableStacking(JSON.parse(data.payload));
    tableStacker.remove();
    tableStacker.stack();

    if (data.confirmed) {
      const element = document.querySelector("#confirmed_" + data.id);
      element.remove();
    } else if (data.remove_table_number) {
      let tableNumber = document
        .querySelector(`[data-id='${data.id}']`)
        .querySelector(`.table-number`);
      tableNumber.remove();
      const element = document.querySelector("#remove_" + data.id);
      element.remove();
    } else if (data.remove_accordion) {
      let accordionParent = document.querySelector(`[data-id='${data.id}']`);
      let accordion = document.querySelector("#accordion-${data.id}");
      accordionParent.remove();
      accordion.remove();
    } else if (data.id) {
      const element = document.querySelector("#remove_" + data.id);
      element.remove();
    }
  }

  newReservation(event) {
    const [_data, _status, xhr] = event.detail;
    this.reservationsTarget.innerHTML = xhr.response;

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector("#reservationModal")
    ).hide();
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();

    document.getElementById("newReservationModal").reset();

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl);
    });
  }

  reservationError(event) {
    const [_data, _status, xhr] = event.detail;
    document.querySelector(".reservationError").innerHTML = xhr.response;

    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".error")
    );
    document.querySelector(".error").querySelector(".toast-body").innerHTML =
      "An error has occurred.";
    toast.show();
  }
}
